import React from "react";

import { cn } from "@/lib/utils.ts";

export const Spinner = ({ useCurrentColor = false, className = "" }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className={cn(
				"background-none shape-auto absolute top-0 m-auto block h-full",
				className,
			)}
			viewBox="0 0 100 100"
			preserveAspectRatio="xMidYMid"
		>
			<circle
				cx="50"
				cy="50"
				r="32"
				strokeWidth="6"
				stroke="#e7e7e7"
				strokeDasharray="50.26548245743669 50.26548245743669"
				fill="none"
				strokeLinecap="round"
				transform="rotate(298.013 50 50)"
			>
				<animateTransform
					attributeName="transform"
					type="rotate"
					dur="1s"
					repeatCount="indefinite"
					keyTimes="0;1"
					values="0 50 50;360 50 50"
				/>
			</circle>
			<circle
				cx="50"
				cy="50"
				r="25"
				strokeWidth="6"
				className={cn({
					["stroke-current"]: useCurrentColor,
				})}
				stroke={useCurrentColor ? undefined : "#addcff"}
				strokeDasharray="39.269908169872416 39.269908169872416"
				strokeDashoffset="39.269908169872416"
				fill="none"
				strokeLinecap="round"
				transform="rotate(-298.013 50 50)"
			>
				<animateTransform
					attributeName="transform"
					type="rotate"
					dur="1s"
					repeatCount="indefinite"
					keyTimes="0;1"
					values="0 50 50;-360 50 50"
				/>
			</circle>
		</svg>
	);
};
